import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
import Letter from "../components/Letter";
import Signature from "../components/Signature";
import Testimonial from "../components/Testimonial";
import GradientText from "../components/GradientText";
import InvisibleAnchor from "../components/InvisibleAnchor";
import MoreLink from "../components/MoreLink";
import Ref from "../components/Reference";
import GumroadCTA from "../components/GumroadCTA";
import Hero from "../components/Hero";
import TaskScroller from "../components/TaskScroller";
import Opa from "../components/Opacity";
import TechnologiesGrid from "../components/TechnologiesGrid";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    <Hero />
    <Layout>{children}</Layout>
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Letter mdxType="Letter">
      <p>{`Every time I build a SaaS product `}<strong parentName="p">{`I waste weeks gluing together standard tooling and functionality`}</strong>{`: `}<span className="">{`user authentication, cookie sessions, `}<Opa value={0.9} mdxType="Opa">{`subscription payments,`}</Opa>{` `}<Opa value={0.8} mdxType="Opa">{`billing management,`}</Opa>{` `}<Opa value={0.7} mdxType="Opa">{`team management,`}</Opa>{` `}<Opa value={0.6} mdxType="Opa">{`GraphQL API,`}</Opa>{` `}<Opa value={0.5} mdxType="Opa">{`transactional emails,`}</Opa>{` `}<Opa value={0.4} mdxType="Opa">{`ESLint,`}</Opa>{` `}<Opa value={0.3} mdxType="Opa">{`TypeScript,`}</Opa>{` `}<Opa value={0.2} mdxType="Opa">{`Prettier,`}</Opa>{` `}<Opa value={0.1} mdxType="Opa">{`Cypress,`}</Opa><Opa value={0.05} mdxType="Opa">{`...`}</Opa></span></p>
      <p>{`That's why I created Bedrock.`}</p>
      <p><strong parentName="p">{`Bedrock combines all the best tools the JS ecosystem has to offer into one solid foundation for your SaaS product`}</strong>{`. It feels fantastic to work with now and sets you up to scale as your code- and userbase grows.`}</p>
      <p>{`And the best part is: you don't have to read thousands of pages of documentation to learn how to use Bedrock. `}<strong parentName="p">{`If you know Next.js and GraphQL you can start coding almost immediately!`}</strong></p>
    </Letter>
    <h2>{`Tools `}<InvisibleAnchor id="tools" mdxType="InvisibleAnchor" /></h2>
    <p>{`Bedrock combines all the best tools the JS ecosystem has to offer and makes them work well together. This means you get `}<strong parentName="p">{`the best developer experience`}</strong>{` and can just `}<strong parentName="p">{`focus on building your product`}</strong>{`.`}</p>
    <p id="rixcy">
  <Testimonial text="The only downside to Bedrock is that it's made me feel like an idiot for not using these technologies earlier. It feels so nice to work with!" name="Rick Booth" company="9xb" title="Head of Front-end Development" avatar="/rixcy.png" link="https://twitter.com/rixcydev" logo={null} mdxType="Testimonial" />
    </p>
    <p>{`There is absolutely no magic in Bedrock, just a bunch of glue code to make all of these tools best friends. You `}<strong parentName="p">{`do not have to know most of these`}</strong>{` technologies to be productive and Bedrock is designed so you can `}<strong parentName="p">{`easily remove all the optional tools`}</strong>{`!`}</p>
    <TechnologiesGrid mdxType="TechnologiesGrid" />
    <p className="flex items-center text-gray-400 font-bold text-sm uppercase justify-center">
  <div className="flex flex-col items-center">
    <div className="h-8 w-16 border-teal-300 border-4 border-solid rounded-md bg-gray-50 mb-1" />
    Core
  </div>
  <div className="flex flex-col items-center ml-12">
    <div className="h-8 w-16 border-gray-200 border-2 border-dashed rounded-md bg-gray-50 mb-1" />
    Optional
  </div>
    </p>
    <p />
    <GumroadCTA mdxType="GumroadCTA" />
    <p id="mkwng">
  <Testimonial text="Something about all these technologies put next to each other made a few things click for me and it's made programming really really fun for me for the first time in a while." name="Michael Wang" company="GitHub" title="Staff Product Designer" avatar="/mkwng.jpeg" link="https://twitter.com/mkwng" logo={null} mdxType="Testimonial" />
    </p>
    <p id="jonny">
  <Testimonial text="I love how my app turned out and it took less than 3 days to build it! I had no experience with GraphQL, Prisma, or Next but I found my way around Bedrock pretty quickly." name="Jonny Burger" company="Remotion" title="Creator" avatar="/jonny-burger.png" link="https://twitter.com/JNYBGR" logo={null} mdxType="Testimonial" />
    </p>
    <p id="erik">
  <Testimonial text="Bedrock saved me a couple hundred hours of work for a stupidly low price." name="Erik Rasmussen" company="Final Form" title="Creator" avatar="/erik-rasmussen.jpg" link="https://twitter.com/erikras/status/1373944468999659521" logo={null} mdxType="Testimonial" />
    </p>
    <p id="jani">
  <Testimonial text="Building a modern SaaS product on top of Next.js and GraphQL is incredible once you get everything set up — but you can waste weeks getting to that point! With Bedrock we were able to focus on what makes our product unique. The codebase also immediately made sense to me, even without reading the documentation." name="Jani Eväkallio" company="Foam" title="Founder" avatar="/jani-evakallio.jpg" link="https://twitter.com/jevakallio" logo={null} mdxType="Testimonial" />
    </p>
    <p id="cassidy">
  <Testimonial text="Typically I've not been happy with boilerplates I've tried out, because I have to deal with coding styles and ideas that are different from mine, and as a result I have to work around them. Bedrock is a delightful exception to the rule. The fact that everything is incredibly modular makes it so that I can tailor my projects the way I want to, while still having a core that I don't have to recreate over and over." name="Cassidy Williams" title="Principal Developer Experience Engineer" company="Netlify" avatar="/cassidoo.jpg" link="https://twitter.com/cassidoo" logo={null} mdxType="Testimonial" />
    </p>
    <h2>{`Features `}<InvisibleAnchor id="features" mdxType="InvisibleAnchor" /></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`User authentication`}</strong></p>
        <p parentName="li">{`Authenticate users without worrying about security and storing passwords. Users get a magic link sent to their email and as soon as they click it they're logged in!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Team support`}</strong></p>
        <p parentName="li">{`Bedrock is ready for teams out of the box. Users can create "projects", each of which has its own billing and members. The pre-built invitation system allows users to invite their colleagues to their projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Subscription payments`}</strong></p>
        <p parentName="li">{`Securely accept subscription payments with Stripe Checkout. Bedrock comes ready for any plans you want (including yearly, per-seat pricing and everything else) and already handles invoices, billing management, PCI compliance, and much more.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Unstyled`}</strong></p>
        <p parentName="li">{`Bedrock comes without any kind of styling or component library because, let's be honest, you'd just delete it anyway. The frontend contains the minimal code necessary to make the functionality work. Make it look the way you want it to by bringing your own component library or using any open source one!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`GraphQL API & integrations`}</strong></p>
        <p parentName="li">{`Enjoy the fantastic developer experience of creating a GraphQL API with Pothos & Prisma and querying it with urql. Bedrock also comes with token-based API authentication, allowing users to programmatically integrate with your product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Adjustable to your preferences`}</strong></p>
        <p parentName="li">{`Bedrock is designed to be modular so you can fine-tune it to your preferences. All tools except for Next.js are completely optional and can easily be removed or swapped for your favorite alternative. (`}<a href="#adjustable">{`learn more`}</a>{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`The best developer experience`}</strong></p>
        <p parentName="li">{`There is a lot of fantastic tooling in the JavaScript ecosystem, but making it all work well together can be tricky. Bedrock comes with everything you need, configured perfectly, so you (and your team) can move fast.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Type-safe from the database to the client`}</strong></p>
        <p parentName="li">{`Move fast thanks to TypeScript catching your bugs before you even hit "Save". Rename a field in the database schema or GraphQL API? TypeScript will immediately highlight where you need to update your code.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Code generation`}</strong></p>
        <p parentName="li">{`Bedrock leverages code generation where it can so you have to code less and move faster. Prisma generates a custom ORM and migrations from your database schema, Pothos generates a custom GraphQL API from your Prisma schema and GraphQL Codegen generates React hooks for fetching data from your GraphQL API.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Infinite scalability`}</strong></p>
        <p parentName="li">{`Thanks to serverless, you never have to worry about scaling your servers. No matter how much traffic your product gets, Next.js will be able to handle it.`}</p>
      </li>
    </ul>
    <p>{`Here is the full list of `}<strong parentName="p">{`everything Bedrock does for you so you don't have to`}</strong>{`:`}</p>
    <div id="is-in-mdx" className="relative pointer-events-none self-start">
  <TaskScroller mdxType="TaskScroller" />
    </div>
    <GumroadCTA mdxType="GumroadCTA" />
    <p />
    <div id="phil">
  <Testimonial text="Bedrock reflects how I'd set up new projects, straight out of the box, and is the perfect balance of both sensible choices & exciting newer techologies." name="Phil Plückthun" company="Formidable Labs" title="Principal Engineer" avatar="/kitten.jpg" link="https://twitter.com/_philpl" mdxType="Testimonial" />
    </div>
    <p />
    <div id="tim">
  <Testimonial text="The Bedrock stack is how we want developers to use Prisma. In fact, we build our own apps exactly like this!" name="Tim Suchanek" company="Prisma" title="Tech Lead" link="https://twitter.com/TimSuchanek" avatar="/tim-suchanek.jpg" logo={<svg xmlns="http://www.w3.org/2000/svg" fill="none" className="h-8" viewBox="0 0 326 128">
        <path fill="#000" fillRule="evenodd" d="M61.568 4.037l44.022 93.482a7.264 7.264 0 01.04 6.109 7.424 7.424 0 01-4.66 4.028l-67.744 20.036A7.47 7.47 0 0131.1 128c-2.505 0-4.915-1.249-6.306-3.407L1.456 88.441a7.233 7.233 0 01-.084-7.763l47.744-77.33c1.354-2.2 3.754-3.491 6.433-3.335a7.062 7.062 0 016.019 4.022v.002zM38.254 118.529l57.479-17c1.632-.482 2.45-2.278 1.733-3.801l-38.02-80.736c-.802-1.703-3.344-1.411-3.728.428l-20.534 98.339c-.37 1.771 1.314 3.29 3.07 2.77z" clipRule="evenodd"></path>
        <path fill="#000" d="M203.536 41.684c-3.134 0-4.701 1.406-4.701 4.219 0 2.832 1.567 4.248 4.701 4.248 1.567 0 2.742-.372 3.526-1.118.783-.746 1.175-1.79 1.175-3.13 0-2.813-1.568-4.22-4.701-4.22zM207.84 85.75h-8.638V54.088h8.638V85.75z"></path>
        <path fill="#000" fillRule="evenodd" d="M319.893 85.75l-1.671-4.305h-.227c-1.454 1.832-2.95 3.101-4.489 3.809-1.538.708-3.544 1.062-6.017 1.062-3.041 0-5.433-.868-7.18-2.605-1.746-1.737-2.619-4.21-2.619-7.42 0-3.36 1.175-5.838 3.525-7.434 2.352-1.595 5.896-2.478 10.635-2.648l5.494-.17v-1.388c0-3.209-1.643-4.814-4.928-4.814-2.53 0-5.504.764-8.921 2.294l-2.86-5.834c3.644-1.907 7.685-2.861 12.121-2.861 4.248 0 7.505.926 9.771 2.776 2.265 1.85 3.398 4.663 3.398 8.439V85.75h-6.032zm-2.549-14.67l-3.342.113c-2.511.076-4.38.529-5.607 1.36-1.228.831-1.841 2.095-1.841 3.795 0 2.435 1.397 3.653 4.191 3.653 2.001 0 3.602-.576 4.8-1.728 1.199-1.151 1.799-2.68 1.799-4.588V71.08z" clipRule="evenodd"></path>
        <path fill="#000" d="M263.831 85.75h8.638V69.862c0-3.285.524-5.682 1.571-7.193 1.049-1.51 2.63-2.266 4.744-2.266 1.643 0 2.846.572 3.612 1.714.763 1.142 1.146 2.856 1.146 5.14V85.75h8.666V65.104c0-4.06-.92-7.01-2.761-8.85-1.84-1.84-4.555-2.761-8.142-2.761-2.077 0-3.941.406-5.593 1.218-1.652.812-2.903 1.954-3.753 3.427h-.764c-1.681-3.096-4.89-4.645-9.629-4.645-2.077 0-3.918.41-5.523 1.232-1.604.82-2.832 1.959-3.681 3.413h-.482l-1.161-4.05h-6.599V85.75h8.638V70.854c0-3.72.5-6.391 1.501-8.015 1.001-1.624 2.606-2.436 4.814-2.436 1.643 0 2.846.572 3.612 1.714.763 1.142 1.146 2.856 1.146 5.14V85.75zM234.866 83.768c2.256-1.7 3.384-4.172 3.384-7.42 0-1.567-.272-2.917-.82-4.05-.548-1.133-1.399-2.133-2.55-3.002-1.151-.868-2.963-1.802-5.437-2.803-2.775-1.114-4.573-1.955-5.396-2.521-.819-.566-1.232-1.236-1.232-2.011 0-1.378 1.275-2.067 3.824-2.067 1.434 0 2.841.217 4.219.65 1.379.436 2.861.992 4.447 1.672l2.605-6.23c-3.605-1.661-7.316-2.492-11.13-2.492-4.003 0-7.094.769-9.273 2.308-2.183 1.539-3.273 3.714-3.273 6.527 0 1.643.261 3.026.78 4.149.519 1.124 1.349 2.12 2.493 2.988 1.14.869 2.931 1.813 5.365 2.832 1.7.718 3.059 1.345 4.079 1.883 1.019.539 1.737 1.02 2.152 1.445.415.425.624.977.624 1.657 0 1.812-1.568 2.718-4.702 2.718-1.53 0-3.3-.255-5.31-.764-2.012-.51-3.819-1.142-5.423-1.898v7.137a22.237 22.237 0 004.56 1.373c1.623.312 3.586.468 5.889.468 4.493 0 7.868-.85 10.125-2.55zM191.703 53.493c1.171 0 2.143.085 2.917.255l-.651 8.1c-.699-.189-1.548-.284-2.549-.284-2.757 0-4.904.708-6.444 2.124-1.538 1.416-2.307 3.4-2.307 5.948V85.75h-8.638V54.088h6.542l1.275 5.324h.424a11.908 11.908 0 013.98-4.29c1.67-1.086 3.487-1.629 5.451-1.629z"></path>
        <path fill="#000" fillRule="evenodd" d="M168.431 57.26c0 4.456-1.392 7.863-4.178 10.223-2.784 2.361-6.744 3.54-11.879 3.54h-3.767V85.75h-8.779V44.346h13.225c5.023 0 8.841 1.081 11.456 3.242 2.615 2.162 3.922 5.386 3.922 9.672zm-19.824 6.57h2.889c2.7 0 4.719-.533 6.06-1.6 1.341-1.067 2.011-2.62 2.011-4.659 0-2.057-.562-3.577-1.685-4.559-1.124-.982-2.884-1.473-5.282-1.473h-3.993V63.83z" clipRule="evenodd"></path>
      </svg>} mdxType="Testimonial" />
    </div>
    <h2>{`FAQ `}<InvisibleAnchor id="faq" mdxType="InvisibleAnchor" /></h2>
    <h3>{`Who made this? `}<InvisibleAnchor id="about" mdxType="InvisibleAnchor" /></h3>
    <p>{`👋 Hi, I'm `}<a parentName="p" {...{
        "href": "https://twitter.com/mxstbr"
      }}>{`@mxstbr`}</a>{`! I'm the creator of `}<a parentName="p" {...{
        "href": "https://github.com/react-boilerplate/react-boilerplate"
      }}>{`react-boilerplate`}</a>{`, one of the most popular early React boilerplates (27k stars) and co-creator of `}<a parentName="p" {...{
        "href": "https://github.com/styled-components/styled-components"
      }}>{`styled-components`}</a>{`, the most popular CSS-in-JS library (32.5k stars).`}</p>
    <p>{`I've spent the past seven years thinking deeply about developer experience and scaling full-stack JavaScript applications. At `}<a parentName="p" {...{
        "href": "https://gatsbyjs.com"
      }}>{`Gatsby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com"
      }}>{`GitHub`}</a>{` and `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{` I had the chance to test my ideas and many tools in varied contexts.`}</p>
    <p>{`I've packed all of that knowledge and experience into Bedrock to allow you to take advantage of it — without having to spend seven years of your life. I hope you enjoy it!`}</p>
    <h3>{`Are there any products online that use Bedrock?`}</h3>
    <p>{`The first app built by an early access customer is online at `}<a parentName="p" {...{
        "href": "https://companies.remotion.dev/"
      }}>{`companies.remotion.dev`}</a>{`! 🎉`}</p>
    <p>{`Other than that, I've been using early versions of this stack for the past year to build my own products, like `}<a parentName="p" {...{
        "href": "https://feedback.fish"
      }}>{`Feedback Fish`}</a>{`. Some of my friends have also used it for their products, like `}<a parentName="p" {...{
        "href": "https://splitbee.io"
      }}>{`Splitbee`}</a>{`, `}<a parentName="p" {...{
        "href": "https://indiebrands.io"
      }}>{`IndieBrands`}</a>{` and `}<a parentName="p" {...{
        "href": "https://pstunnel.com"
      }}>{`PSTunnel`}</a>{`!`}</p>
    <p>{`I've personally also used variations of this stack for internal projects at GitHub and for Spectrum.`}</p>
    <h3>{`Can I actually easily remove the optional stuff? `}<InvisibleAnchor id="adjustable" mdxType="InvisibleAnchor" /></h3>
    <p>{`Yes! I explicitly designed Bedrock to make everything except for Next.js and GraphQL easy to remove and/or ignore. There will also be extensive documentation on how to remove every single optional tool.`}</p>
    <p>{`Removing most of the optional tools only requires deleting a single file or folder (ESLint, Prettier, Postmark, Pothos, GraphQL Codegen, urql), only a few also require deleting code in a handful of other files (Passport, Stripe, Prisma). Either way it's not going to take more than five minutes.`}</p>
    <h3>{`Can I get support?`}</h3>
    <p>{`There is an invite-only Discord community for folks who have purchased Bedrock to ask questions and help each other out. Once you complete your order, you will get an invite link for you and your team.`}</p>
    <p>{`On top of that, if your company would like an ongoing technical support contract contact me at `}<a parentName="p" {...{
        "href": "mailto:bedrock@mxstbr.com"
      }}>{`bedrock@mxstbr.com`}</a>{`.`}</p>
    <h3>{`How many products can I use Bedrock for?`}</h3>
    <blockquote>
      <p parentName="blockquote">{`You are granted a license to use the SOFTWARE as the basis of one application, so long as that application is owned and operated by you, the LICENSEE, or is owned and operated by clients of LICENSEE.`}</p>
    </blockquote>
    <p>{`Every license you purchase allows you to use Bedrock to build one product, no matter how many people work on that single product. You can purchase unlimited licenses.`}</p>
    <p>{`There is also an unlimited licenses that allows you to use Bedrock for as many products as you want.`}</p>
    <h3>{`How long can I use Bedrock?`}</h3>
    <p>{`You can use Bedrock for the entire lifetime of your product. There are no ongoing costs or time limitations.`}</p>
    <h3>{`Can I get a refund? `}<InvisibleAnchor id="refund" mdxType="InvisibleAnchor" /></h3>
    <p>{`I offer a 14 day money-back guarantee. If you're not satisfied with Bedrock after using it for two weeks I'll refund you your money!`}</p>
    <h3>{`Do I get access to the entire codebase?`}</h3>
    <p>{`Yes! Once you purchase Bedrock you will get a license key, which you can `}<a parentName="p" {...{
        "href": "/redeem"
      }}>{`redeem for access to the private GitHub repository`}</a>{`.`}</p>
    <h3>{`Do I get access to updates?`}</h3>
    <p>{`You will have access to all future updates since all development happens in the private GitHub repository!`}</p>
    <p>{`Note that applying the updates to your product might be tricky depending on how much you change the existing Bedrock code. However, you can always at least look at the changes between versions and copy-paste the changes you like manually.`}</p>
    <h3>{`Do you offer ongoing support contracts?`}</h3>
    <p>{`Contact me at `}<a parentName="p" {...{
        "href": "mailto:bedrock@mxstbr.com"
      }}>{`bedrock@mxstbr.com`}</a>{` if your company would like ongoing support for their product using Bedrock.`}</p>
    <h3>{`I have other questions!`}</h3>
    <p>{`Feel free to contact me at `}<a parentName="p" {...{
        "href": "mailto:bedrock@mxstbr.com"
      }}>{`bedrock@mxstbr.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      